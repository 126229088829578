import React, { useState } from "react";

import styled from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";

import { StaticImage } from "gatsby-plugin-image";

import arrow from "src/assets/images/svg/slider-arrow.svg";
import map from "src/assets/images/svg/map.svg";

import Title from "src/components/typography/Title.js";
import SubTitle from "src/components/typography/SubTitle.js";

import BodyText from "src/components/typography/BodyText.js";
import MainWrapper from "src/components/global/MainWrapper.js";

const Wrapper = styled.section`
  background-color: ${colors.light};
  width: 100%;
  height: 750rem;
  ${MainWrapper} {
    display: flex;
    @media (max-width: ${mediaQuery.tablet}) {
      flex-direction: column;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: initial;
  }
`;

const LeftWrapper = styled.div`
  width: 50%;
  display: flex;

  padding: 30rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    padding: 50rem 0;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const StyledTitle = styled(Title)`
  color: ${colors.green};
  max-width: 510rem;
`;

const StyledBodyText = styled(BodyText)`
  max-width: 480rem;
  margin-top: 20rem;
  margin-bottom: 90rem;
  a {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    font-family: inherit;
    text-decoration: underline;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50rem;
  }
`;
const StyledSubTitle = styled(SubTitle)`
  color: ${colors.green};
  margin-top: 50rem;
`;

const SliderWrapper = styled.div`
  width: calc(50% + ${distances.gap}rem);

  position: relative;
  left: ${distances.gap}rem;
  /* height: 50vw; */
  overflow: hidden;
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    /* width: calc(100% + ${distances.gap}rem); */
    /* left: -${distances.gap / 2}rem; */
    height: 120vw;
    width: 100%;

    left: 0;
  }
`;

const BottomRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${BodyText} {
    width: 240rem;
  }
`;

const ArrowSlider = styled.img`
  /* cursor: pointer; */
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  /* :hover {
    transform: translateX(20rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  } */
  @media (max-width: ${mediaQuery.tablet}) {
    align-self: flex-end;
    transform: rotate(90deg) !important;
  }
`;

const SliderImgWrapper = styled.div`
  width: 100%;
  position: relative;
  flex-shrink: 0;
  height: calc(100% - ${distances.pageMargin * 2}rem);
  margin: ${distances.pageMargin}rem 0;
  /* background-color: red; */
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  display: flex;
  flex-direction: row-reverse;
  img {
    /* margin: ${distances.pageMargin}rem; */
    width: 100%;
  }
  /* .imgwrapper {
    height: 100%;
    width: 100%;
  } */
`;

const FirstSection = () => {
  const [sliderPos, setSliderPos] = useState(0);

  const NextSlide = () => {
    if (sliderPos === 2) {
      setSliderPos(0);
    } else {
      setSliderPos(sliderPos + 1);
    }
  };

  return (
    <Wrapper id="dostawa">
      <MainWrapper>
        <LeftWrapper>
          <InfoWrapper>
            <div>
              <StyledTitle
                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                Dostawa gratis na terenie całej Warszawy!
              </StyledTitle>
              <StyledSubTitle
                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                Dowozimy też poza warszawą!
              </StyledSubTitle>
              <StyledBodyText
                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                zadzwoń <a href="tel:22 400 66 33">22 400 66 33</a> aby poznać
                indywidualne warunki dostawy na terenie województwa
                mazowieckiego
              </StyledBodyText>
            </div>
            <BottomRow>
              <BodyText
                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                *Sprawdź minimalne kwoty na mapie dowozu
              </BodyText>
              <ArrowSlider
                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-easing="ease"
                data-sal-duration="500"
                // onClick={() => {
                //   NextSlide();
                // }}
                src={arrow}
              />
            </BottomRow>
          </InfoWrapper>
        </LeftWrapper>
        <SliderWrapper>
          <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <img src={map} />
            {/* <StaticImage
              placeholder="blurred"
              src="../..//assets/images/img/slider/1.JPG"
              alt="PinkVegan"
              objectFit="cover"
              height="100%"
              className="imgwrapper"
            /> */}
          </SliderImgWrapper>
          {/* <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              src="../..//assets/images/img/slider/2.png"
              alt="PinkVegan"
            />
          </SliderImgWrapper>
          <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              src="../..//assets/images/img/slider/3.png"
              alt="PinkVegan"
            />
          </SliderImgWrapper> */}
        </SliderWrapper>
      </MainWrapper>
    </Wrapper>
  );
};

export default FirstSection;
