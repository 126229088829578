const promotionsListLocal = [
  // {
  //   first: "-30%",
  //   main: "Poniedziałek",
  //   description: "30% zniżki na drugie danie główne.",
  //   go: false,
  // },

  // {
  //   first: "-50%",
  //   main: "Wtorek",
  //   description: "50% zniżki na drugą porcję sushi.",
  //   go: false,
  // },
  // {
  //   first: "gratis",
  //   main: "Środa",
  //   description: "drugi deser gratis.",
  //   go: false,
  // },
  // {
  //   first: "2 w 1",
  //   main: "Czwartek",
  //   description: "2 przystawki w cenie 1.",
  //   go: false,
  // },
  // {
  //   first: "-30",
  //   main: "Piątek",
  //   description: "30% zniżki na futo maki",
  //   go: false,
  // },
  // {
  //   first: "-50",
  //   main: "Promocja na cały tydzień",
  //   description: "50% zniżki na drugie piwo/prosecco",
  //   go: false,
  // },
  {
    first: "HAPPY HOURS",
    main: "HAPPY HOURS  16-19 pon-piątek,  DRUGA ROLKA GRATIS",
    description:
      "dotyczy drugiej takiej samej porcji 4 szt. z kategorii california maki",
    go: false,
  },
  // {
  //   first: "-50%",
  //   main: "HAPPY HOURS  16-19 pon-piątek, drugi koktaj 50 % taniej",
  //   description: "",
  //   go: false,
  // },
  {
    first: "5 pieczątek",
    main: "Kimidori & Pink Vegan w Embargo",
    description:
      "Pobierz aplikację Embargo i zbieraj wirtualne pieczątki na karcie lojalnościowej Kimidori & Pink Vegan. Każdy lunch nagradzamy pieczątką. Zbierz 5 pieczątek, a przy kolejnej wizycie lunch otrzymasz w prezencie.",
    go: false,
  },
];

export default promotionsListLocal;
